.welcome-section {
    text-align: center;
    padding: 50px 0;
    background-color: #002E5D;
    color: #fff;
}

.home-page * p {
    font-size: 1rem;
}

.services-section {
    padding: 50px 0;
}

.service-card {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.service-card:hover {
    transform: translateY(-5px);
}

.welcome-section-header {
    color: #fff;
}

.home-page {
    min-height: calc(100vh - 120px);
}

.services-card-button {
    bottom: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.primary-action-btn {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.7px;
    padding: 10px 20px;
}