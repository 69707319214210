.divider {
    position: relative;
    /* display: flex; */
    justify-content: center;
    align-content: center;
    height: 100%;
  }
  
  .divider:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    background-color: #ddd;
    height: 100%;
  }
  
  .divider span {
    height: auto;
    display: block;
    background: #fff;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 5px 0;
  }
  
  nav.navbar.navbar-expand.navbar-light.bg-white.fixed-fixed{
    background-color: #002E5D !important;
    color: white;
  }
  
  .header-btn{
    color: white !important;
  }
  
  .image-div{
    background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat;
    text-align:center;
  }

  .orgButton{
    border: 1px outset blue;
    background-color: #002E5D;
    height:6rem;
    width:6rem;
    color:white;
    box-shadow: 5px 5px grey;
 }

 center-img {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}