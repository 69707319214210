body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-bullets {
  list-style-type: none;
}

.sw-btn-next {
  background-color: #002E5D !important;
  border-color: #002E5D !important;
}

.sw-btn-prev {
  background-color: #002E5D !important;
  border-color: #002E5D !important;
}

.hr-nav {
  display: block;
}

.vr-nav {
  display: none;
}

@media screen and (max-width: 575px) {
  .hr-nav {
    display: none;
  }

  .vr-nav {
    display: block;
  }

}